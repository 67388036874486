.spaceBar {
    margin-top: 110px;
}
.spaceBar2 {
    margin-top: 70px;
}

.spaceBar1 {
    margin-top: 120px;
}

.timelineContainer {
    width: 100%;
    height: 600px;
    align-items: center;
    justify-content: center;
    flex: auto;
}

.missionContainer {
    width: 100%;
    height: 600px;
    align-items: center;
    justify-content: center;
    flex: auto;
    display: flex;
    background-color: #101010;
}

.visionContainer {
    width: 100%;
    height: 400px;
    align-items: center;
    justify-content: center;
    flex: auto;
    display: flex;
    background-color: #FFFFFF;
}

.valuesContainer {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex: auto;
}

.containerMissionTitle {
    color: #e65025;
}

.containerVisionTitle {
    color: #e65025;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.containerValuesTitle {
    color: #e65025;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.colValuesAlign {
    color: #e65025;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.missionTextColor {
    color: #32a283;
    font-weight: 200;
}

.visionTextColor {
    color: #101010;
    font-weight: 200;
    text-align: end;
}

.valuesTextColorSUS {
    color: #32a283;
    font-weight: 400;
    text-align: end;
}
.valuesTextColorINV {
    color: #e65025;
    font-weight: 400;
    text-align: end;
}
.valuesTextColorQUA {
    color: #0B9DA1;
    font-weight: 400;
    text-align: end;
}
.valuesTextColorSAU {
    color: #FC5A2B;
    font-weight: 400;
    text-align: end;
}


.cardClass {
    padding-top: 20px;
}