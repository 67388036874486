.bg-gray {
  background-color: #F3F3F3;
}

.timelineContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  padding: 0px;
  margin-bottom: 0px;

}

.alignIcon {
  align-items: center;
  justify-content: center;
}


.spaceBar {
  margin-top: 110px;
}

.ourVisionTitle {
  color: #e65025;
  padding-top: 40px;
  padding-bottom: 60px;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-weight: 400;
  background-color: #F3F3F3;
}

.timelineElementTitle {
  color:#36383c
}

.timelineElementNewTitle {
  color:#0B9DA1
}

