
.accordionBootstrap {
    width: 30%;
    height: 600px;
    margin-top: 78px;
}


.accordion-primax {
    width: 50%;
    margin-left: 120px;
    margin-top: 80px;
}


/* seuEstilo.css */
.primeiro-accordion-header {
    background-color: black;
    /* Cor de fundo desejada */
/*     color: #fff; */
    /* Cor do texto desejada */
    /* Outros estilos conforme necessário */
}

.faqSectionDIV {
    width: 100%;
    display: flex;
/*     flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
    justify-content:space-around ;
    align-items:flex-start ;
    overflow-x: auto;
    padding: 0px; /* Adiciona um espaço interno */
    height: auto;
    margin-bottom: 30px;
}


@media (max-width: 768px) {
    .faqSectionDIV {
        flex-direction: column; /* Altera a direção do flex para coluna em telas menores */
        align-items: center;
    }
}

.faqMarginContainer {
    margin: 40px; /* Centraliza o container na tela */
    max-width: 1000px; /* Define a largura máxima para evitar que o conteúdo se estenda demais */
}

.textAlignCard {
    text-align:left
}

.cardBorder {
    border: none;
}

.titleFaq {
    margin-bottom: 30px;
}

.aboutButton1 {
    color:#ededed;
    background-color: #32a283;
    border-radius: 30px;
    border-color: #32a283;
    margin-top: 15px;
    margin-bottom: 15px;
}

.aboutButton1:hover {
    /* Estilos para hover */
    color: white;
    background-color: #228569; /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #228569;
    border-radius: 30px;
  }


  .colAlignCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}