
.header {
  transition: all 0.5s;
  background: #fff;
  z-index: 997;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.header.header-scrolled {
  border-color: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}


.header .logoHeader img {
  max-height: 45px;
}

.socialMedia1 {
height: 40px;
background-color: #32A283;
margin-top: 0px;
padding-top: 0px;
}


.socialMedia {
  height: 25;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  align-self: flex-end;
  display: block;
  max-width: 1200px;

}

.socialMediaSpace {
  margin-right: 15px;
}

.socialBar {
  align-items: end;
  justify-content: end;
}

.navBarVerticalAlign {
vertical-align: middle;
margin: 0 0 0 0;
}

.navBarRightMargin {
  padding-right: 15px;
}

.tooltip {
  position: absolute;
  z-index: 9999;

}