.primaxGreenColor {
  background-color: #32A283;
}

.primaxBlackColor {
  background-color: #101010;
}

.address {
  height: 80px;
  align-items:flex-start;
  color: #FFFFFF;
  font-size:medium

}

.logo-footer {
  height: 70px;
}

.copyright {
  font-size: small;
  align-items: flex-start;
  color: #FFFFFF
}


.socialMedia {
  height: 25;
  margin-top: 70px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  align-self: flex-end;
  display: flex;

}

.socialMediaSpace {
  margin-right: 15px;
}

.innerPageColor {
  background-color: #D9D9D9;
}

.primaxGreenColor {
  background-color: #32A283;
}