.buyYoursDIVBlue {
    width: 100%;
    display: flex;
/*     flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
    justify-content:space-around ;
    align-items: center;
    background-color: white;
    overflow-x: auto;
    padding: 0px; /* Adiciona um espaço interno */
    height: 600px;
}


.progressBarStyle {
    width: 60%;
    display: flex;
    margin-top: 70px;
}

.progressBarStyleDIV {
    width: 100%;
    height: 600px;
    display: flex;
    margin-top: 0px;
    justify-content:center;
    align-items:center;
    background-color: #121212;
}


.spaceBar {
    margin-top: 110px;
}

.orange-progress-bar .progress-bar {
    background-color: #e65025;
}

.colAlignCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.buttonCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.socialBar {
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
align-content: center;
}

.whiteWrite {
    color: #c1c1c1;
}