.App-logo {
  height: 40vmin;
  pointer-events: none;
  fill: #32A283;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-logo-semrodar {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-instagram {
  width: 6vh;
  pointer-events: none;
}

.bg-black {
  background-color: #101010;
}

.bg-green {
  background-color: #32A283;
}

.icone-colorido {
  fill: #32A283;
}

.largura-60 {
  width: 60%
}

.altura-30 {
  height: 30px;
}

.altura-450 {
  height: 450px;
}

.largura-30 {
  width: 20px;
  display: flex;

}


.margin-top-1 {
  margin-top: 110px;
}

.spaceBar {
  margin-top: 110px;
}

.margin22 {
  margin-top: 100px;
  padding-top: 50px;
  background-color: #101010;
}

.rotateIcon {
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 110px;
}

.textGetInTouch {
  text-align: center;
  color:#F3F3F3;
}

.fullAuto {
  background-color: #101010;
  height: calc(100vh - 680px);
}

.bg-black1 {
  background-color: #101010;
}
