.advantageSectionDIV {
    width: 100%;
    display: flex;
/*     flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
    justify-content:space-around ;
    align-items: center;
    background-color: white;
    overflow-x: auto;
    padding: 0px; /* Adiciona um espaço interno */
}


@media (max-width: 768px) {
    .advantageSectionDIV {
        flex-direction: column; /* Altera a direção do flex para coluna em telas menores */
        align-items: center;
    }
}

.cardDiv {
    flex: 1; /* Ocupa a largura disponível */
    margin: 20px 0px 20px 0px; /* Adiciona margens entre os cards */
}


.marginContainer {
    margin: 50px; /* Centraliza o container na tela */
    max-width: 1400px; /* Define a largura máxima para evitar que o conteúdo se estenda demais */
}

.cardClass {
    padding-top: 20px;
}

.fontGoogle {
    font-family: georamara;
}