.getOfferDIV {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-x: auto;
    padding: 0px;
    /* Adiciona um espaço interno */
    height: auto;
    background-color: #ccc
}


.rotateIcon-mobile {
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100px;
}

.rotateIcon-hd {
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 160px;
}

.rotateIcon-fullhd {
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 220px;
}

.colAlignCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.colAlignLeft {
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
    display: flex;
    margin-bottom: 20px;
}

.buttonCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.buttonCenter1 {
    width: 250px;
    margin-top: 30px;
}

.aboutButton {
    color: #e65025;
    background-color: #101010;
    border-radius: 30px;
    border-color: #e65025;
}

.aboutButton:hover {
    /* Estilos para hover */
    background-color: #32a283;
    /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #32a283;
    border-radius: 30px;
}

.blackFont {
    color: #101010;
}
.grayFont {
    color: #7a7a7a;
}


.sendButton {
    color: #101010;
    background-color: #FC5A2B;
    border-radius: 40px;
    width: 100px;
    border-color: #FC5A2B;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.sendButton:hover {
    /* Estilos para hover */
    background-color: #e65025;
    color: #050505;
    /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #e65025;
    border-radius: 40px;
}

.sendButton:disabled {
    /* Estilos para hover */
    background-color: #666666;
    color: #ffffff;
    /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #666666;
    border-radius: 40px;
}

.formsMobile {
    width: 550px;
    margin-top: 25px;
    margin-left: 20px;
    margin-right: 20px;
}

.formsHD {
    width: 650px;
    margin-top: 25px;
    margin-left: 40px;
    margin-right: 40px;
}

.formsFullHD {
    width: 800px;
    margin-top: 25px;
    margin-left: 60px;
    margin-right: 60px;
}


.formsInside {
    border-radius: 40px;
    border: 2px solid #ccc;
    /* Estilo da borda */
    padding: 10px;
    /* Preenchimento interno */
    font-size: 18px;
    padding-left: 20px;
}

.formFilledDIVMobile {
    height: 87vh;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}
.formFilledDIVHD {
    height: 87vh;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.h5width {
    width: 500px;
}


.checkbox-with-margin .form-check-label {
    margin-left: 15px;
    text-align: left;
    display: flex
}

.switch-with-margin .form-check-label {
    margin-left: 15px;
    text-align: left;
    display: flex;
    font-size: 18px;
    
}

.switch-with-margin .form-check-input {
height: 24px;
width: 38px;
    
}


.floatingClass label {
    margin-left: 2.5px;
  }

.allDoneAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.allDoneAnimation1 {
    width: 100px;
}

.ticketAnimation {
    display: flex;
    justify-content: center;
    width: 100%;

}

.ticketAnimation1 {
    width: 80px;
}

.ticketId {
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 0px;
}

.ticketNumber {
    background-color: #ffffff;
    border-radius: 15px;
    vertical-align: middle;
    display: flex;
    margin-left: 15px;

}

.copyBackgroundColor {
    background-color: #e7e7e7;
    border-radius: 0px 15px 15px 0px;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    justify-content: center;
    width: 50px;
}

.bg-color-foot {
    background-color: #101010;
    height: 15vh;
    display: flex;
}
.bg-color-footHD {
    background-color: #101010;
    height: 13vh;
    display: flex;
    width: 100%;
}

.logo-footer {
    padding-left: 15px;
}


.forwardAnimation {
    height: 60px;
    
}

.footerContainer {
    width: 100%;
}
