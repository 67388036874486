.registerForYoursDIV {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-x: auto;
    padding: 0px;
    /* Adiciona um espaço interno */
    height: 530px;
    background-color: #32a283
}


.colAlignCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.buttonCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.buttonCenter1 {
    width: 250px;
    margin-top: 30px;
}

.aboutButton {
    color: #e65025;
    background-color: #101010;
    border-radius: 30px;
    border-color: #e65025;
}

.aboutButton:hover {
    /* Estilos para hover */
    background-color: #32a283;
    /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #32a283;
    border-radius: 30px;
}

.blackFont {
    color: #101010;
}


.sendButton {
    color: #101010;
    background-color: #FC5A2B;
    border-radius: 40px;
    width: 100px;
    border-color: #FC5A2B;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.sendButton:hover {
    /* Estilos para hover */
    background-color: #e65025;
    color: #050505;
    /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #e65025;
    border-radius: 40px;
}

.forms {
    width: 450px;
margin-top: 25px;
}

.formsInside {
    border-radius: 40px;
    border: 2px solid #ccc; /* Estilo da borda */
    padding: 10px; /* Preenchimento interno */
    font-size: 18px;
    padding-left: 20px;
}

.lockerLockedDIV {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.lockerLocked1 {
    width: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.lockerLocked2 {
    width: 75px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.lockerLocked3 {
    width: 90px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}