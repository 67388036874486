.bg-color {
    background-color: #d4d4d444;
    width: 100%;
    height: auto;
}

.App-logo {
    width: 200px;
}

.colAlignCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.mt-200 {
    margin-top: 200px;
    width: 40px;
}
.w-80{
    width: 80%;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
}

.colAlignLeft {
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    text-align: left;
    display: flex;
    margin-bottom: 20px;
}

.logo {
    width: 50px; /* Ajuste conforme necessário */
}

.line {
    border-top: 2px solid black;
}

.terms-content {
    text-align: justify;
}

@media (max-width: 768px) {
    .logo {
        width: 30px; /* Ajuste conforme necessário */
    }
}