.buyYoursDIV {
    width: 100%;
    display: flex;
    justify-content:space-around ;
    align-items: center;
    overflow-x: auto;
    padding: 0px; /* Adiciona um espaço interno */
    height: 600px;
    background-color: #101010;
}


.progressBarStyle {
    width: 60%;
    display: flex;
    margin-top: 70px;
}

.progressBarStyleDIV {
    width: 100%;
    height: 600px;
    display: flex;
    margin-top: 0px;
    justify-content:center;
    align-items:center;
    background-color: #101010;
}


.spaceBar {
    margin-top: 110px;
}

.orange-progress-bar .progress-bar {
    background-color: #e65025;
}

.colAlignCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.buttonCenter {
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}
.buttonCenter1 {
    width: 250px;
    margin-top: 30px;
}

.aboutButton {
    color: #e65025;
    background-color: #101010;
    border-radius: 30px;
    border-color: #e65025;
}

.aboutButton:hover {
    /* Estilos para hover */
    background-color: #32a283; /* Por exemplo, mude a cor de fundo ao passar o mouse */
    border-color: #32a283;
    border-radius: 30px;
  }